import React, { Component } from "react";

import styled from "styled-components";

import { auth } from "api/firebase";
import { fetchUserDataByUid, updateUsername, updateName, updateSocial } from "api/db";
import { doSignOut } from "api/auth";

import AuthUserContext from "../components/Session/AuthUserContext";
import withAuthorization from "../components/Session/withAuthorization";

import Label from "components/Elements/Label";
import Button from "components/Elements/Button";

import { Link } from "gatsby";

const Container = styled.div`
  margin: 0 auto;
  max-width: 400px;
  text-align: center;
  padding: 24px;
  display: flex;
  flex-direction: column;

  button {
    margin: 16px 0px;
  }
`;

const Account = styled.div`
  align-self: center;
`;

const updateByPropertyName = (propertyName, value) => () => ({
  [propertyName]: value
});

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      new_id: "",
      new_name: "",
      uid: "",
      bio: "",
      present: false,
      twitter: "",
      public_email: "",
      website: "",
      github: ""
    };
  }

  componentDidMount() {
    let uid = auth.currentUser.uid;
    this.setState({ uid: uid });
    this.getUserData(uid);
  }

  getUserData = uid => {
    fetchUserDataByUid(uid).on(
      "value",
      function(snapshot) {
        let data = snapshot.val();
        this.setState({
          id: data.id,
          name: data.username,
          bio: data.bio || "",
          twitter: data.twitter || "",
          public_email: data.public_email || "",
          website: data.website || "",
          github: data.github || ""
        });
      }.bind(this)
    );
  };

  onUsernameChangeSubmit = event => {
    event.preventDefault();
    let new_id = this.state.new_id.toLowerCase();
    if (/^ *$/.test(new_id)) {
      alert("Your username cannot be blank.");
    } else if (new_id.indexOf(" ") >= 0) {
      alert("Your username cannot have spaces.");
    } else {
      updateUsername(this.state.uid, new_id);
    }
  };

  onNameChangeSubmit = event => {
    event.preventDefault();
    updateName(this.state.uid, this.state.new_name);
  };

  onInformationSubmit = event => {
    event.preventDefault();
    console.log(this.state.bio);
    updateSocial(
      this.state.uid,
      this.state.bio,
      this.state.twitter,
      this.state.public_email,
      this.state.website,
      this.state.github
    );
  };

  render() {
    const {
      id,
      name,
      bio,
      twitter,
      public_email,
      website,
      github
    } = this.state;
    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div>
            <Container className="text-gray-800">
              <Account>
                <h2 className="text-4xl tracking-tight leading-snug font-extrabold text-gray-700 mt-4">
                  {name} <br />
                  <Link className="gradient-text" to={`/user/${id}`}>
                    @{id}
                  </Link>
                </h2>
                <br />
                <div>
                  <Label> Change Username </Label>
                  <form onSubmit={this.onUsernameChangeSubmit}>
                    <input
                      value={this.state.new_id}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("new_id", event.target.value)
                        )
                      }
                      type="text"
                      placeholder={`${id}`}
                      className="input"
                    />
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Change Username</button>
                  </form>
                </div>
                <br />
                <div>
                  <Label> Change Name </Label>
                  <form onSubmit={this.onNameChangeSubmit}>
                    <input
                      value={this.state.new_name}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("new_name", event.target.value)
                        )
                      }
                      type="text"
                      placeholder={`${name}`}
                      className="input"
                    />
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Change Name</button>
                  </form>
                </div>
                <br/>
                <div>
                  <Label> Social </Label>
                  <form onSubmit={this.onInformationSubmit}>
                    <input
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("twitter", event.target.value)
                        )
                      }
                      type="text"
                      placeholder={
                        `twitter username: ${twitter}` || "twitter username"
                      }
                      className="input"
                    />
                    <input
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("github", event.target.value)
                        )
                      }
                      type="text"
                      placeholder={
                        `github username: ${github}` || "github username"
                      }
                      className="input"
                    />
                    <input
                      onChange={event =>
                        this.setState(
                          updateByPropertyName(
                            "public_email",
                            event.target.value
                          )
                        )
                      }
                      type="text"
                      placeholder={
                        `public email: ${public_email}` || "public email"
                      }
                      className="input"
                    />
                    <input
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("website", event.target.value)
                        )
                      }
                      type="text"
                      placeholder={`website: ${website}` || "website"}
                      className="input"
                    />
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Update Profile</button>
                  </form>
                </div>
                <br />
                <div>
                  <Label> Update Bio </Label>
                  <form onSubmit={this.onInformationSubmit}>
                    <textarea
                      value={this.state.bio}
                      onChange={event =>
                        this.setState(
                          updateByPropertyName("bio", event.target.value)
                        )
                      }
                      type="text"
                      placeholder={`${bio}` || "Write in markdown..."}
                      className="input"
                    />
                   <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit">Update Bio</button>
                  </form>
                </div>
                <br />
                <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => doSignOut()}>Sign Out</button>
              </Account>
            </Container>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const authCondition = authUser => !!authUser;

export default withAuthorization(authCondition)(AccountPage);
